<template>
  <b-modal
    id="permission-select"
    :visible="shallShowPermissionSelectModal"
    :title="(title !== '')? title : `${$t('Select')} ${$t('TitleBooking.Permission')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-permission-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ (title !== '')? title : `${$t('Select')} ${$t('TitleBooking.Permission')}` }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="(busy === false) ? $emit('update:shall-show-permission-select-modal', false) : false"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <b-card
        class="p-0 m-0"
        style="margin-top: 0.8rem !important;"
      >
        <b-card-title style="margin-bottom: 0.4rem !important;">
          <div>
            <small
              v-if="permissionData.name !== undefined && permissionData.name !== ''"
            >
              {{ (title !== '')? title : `${$t('Select')} ${$t('Permission')}` }}
            </small>
            <b-alert
              v-if="permissionData.name !== undefined && permissionData.name !== ''"
              show
              variant="success"
              style="margin-top: 0.2rem;"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />
                <span class="ml-25">{{ permissionData.name }}</span>
              </div>
            </b-alert>
          </div>
        </b-card-title>

        <div style="margin: 0rem 0rem 1rem 0rem;">
          <b-row>
            <b-col
              cols="12"
              sm="4"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="8"
            >
              <div
                class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
              >
                <b-form-input
                  ref="refSearch"
                  v-model="q"
                  class="d-inline-block m-0"
                  :placeholder="`${$t('Search')}...`"
                />
                <b-button
                  size="md"
                  variant="outline-success"
                  style="margin-left: 0.5rem;"
                  class="px-75"
                  @click="onResetSearch"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    size="14"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div
          class="pb-75 pl-75 pr-75 pt-75"
          style="border: 1px solid #cccccc; background-color: #eeeeee; border-radius: 0.5rem;"
        >
          <b-row>
            <b-col
              v-for="tableCol in tableColumns"
              :key="tableCol.key"
              role="columnheader"
              :cols="tableCol.col"
              :class="tableCol.class"
            >
              <div>
                {{ tableCol.label }}
              </div>
            </b-col>
          </b-row>
        </div>
        <VuePerfectScrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHandle"
        >
          <b-row
            v-for="item in permissionList"
            :key="item.id"
            class="mt-1 mb-1 mr-1"
          >
            <b-col
              cols="2"
              class="text-center"
            >
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `info` "
                      class="px-1 py-25"
                    >
                      {{ item.id }}
                    </b-badge>
                  </span>
                </b-list-group>
              </div>
            </b-col>

            <b-col
              cols="6"
              class="text-center"
            >
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <span style="font-size: 14px;">
                    {{ item.name }}
                  </span>
                </b-list-group>
              </div>
            </b-col>

            <b-col
              cols="2"
              class="text-center"
            >
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `info` "
                      class="px-1 py-25"
                    >
                      {{ (item.permission_module !== undefined)? item.permission_module.length : 0 }}
                    </b-badge>
                  </span>
                </b-list-group>
              </div>
            </b-col>

            <b-col
              cols="2"
              class="text-center"
            >

              <div
                style="margin-top: 0.3rem;"
              >
                <b-button
                  v-if="checkSelect(item)"
                  size="sm"
                  variant="primary"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Select') }}</span>
                </b-button>

                <b-button
                  v-else
                  size="sm"
                  variant="outline-success"
                  style="margin-left: 0rem;"
                  @click.stop="onSelect(item)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="14"
                    style="margin-right: 0.4rem;"
                  />
                  <span class="align-middle">{{ $t('Selected') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </VuePerfectScrollbar>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BBadge,
  BFormInput,

  BOverlay,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import storePermission from './storePermission'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BBadge,
    BFormInput,
    BOverlay,

    VuePerfectScrollbar,
  },
  model: {
    prop: 'shallShowPermissionSelectModal',
    event: 'update:shall-show-permission-select-modal',
  },
  props: {
    shallShowPermissionSelectModal: {
      type: Boolean,
      required: true,
    },
    permissionData: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      busy: false,
      skin: store.state.appConfig.layout.skin,
      settings: {
        maxScrollbarLength: 60,
      },
      perPage: 100,
      totalItem: 0,
      currentPage: 1,
      sortBy: 'name',
      isSortDirDesc: false,
      locale: 'th',
      q: '',
      permissionList: [],
      tableColumns: [],
      appCode: $themeConfig.app.appCode,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.permissionList?.length
      return {
        from: (localItemsCount ? 1 : 0),
        to: localItemsCount,
        of: this.totalItem,
      }
    },
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    locale() {
      // this.refetchData()
    },
    q() {
      this.refetchData()
    },
    shallShowPermissionSelectModal(value) {
      if (value === true) {
        this.permissionList = []
        this.currentPage = 1
        this.getListPermissionSelect()
      }
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('ID')}`,
      name: `${this.$i18n.t('Name')}`,
      module: `${this.$i18n.t('Module')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      {
        key: 'id', sortable: true, label: msgList.id, col: 2, class: 'text-center',
      },
      {
        key: 'name', sortable: true, label: msgList.name, col: 6,
      },
      {
        key: 'module', sortable: true, label: msgList.module, col: 2, class: 'text-center',
      },
      {
        key: 'actions', label: msgList.actions, col: 2, class: 'text-center',
      },
    ]
  },
  methods: {
    getListPermissionSelect() {
      this.showOverlay = true
      const params = {
        lineOaId: this.lineOaId,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.q,
        appCode: this.appCode,
      }
      store
        .dispatch('permission-store/getListPermissionSelect', params)
        .then(response => {
          const { list, total } = response.data
          if (this.currentPage === 1) {
            // console.log('first')

            if (this.$refs.refSearch !== undefined) {
              this.$refs.refSearch.focus()
            }
            this.permissionList = list
          } else {
            // append
            // console.log('append')
            list.forEach(ll => {
              this.permissionList.push(ll)
            })
            // console.log(this.permissionList)
          }
          this.totalItem = total
          this.showOverlay = false
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    refetchData() {
      this.permissionList = []
      this.currentPage = 1
      this.getListPermissionSelect()
    },
    scrollHandle(event) {
      if (event.target.scrollTop + event.target.clientHeight >= (event.target.scrollHeight - 2)) {
        if (this.showOverlay === false) {
          const totalListItem = this.permissionList.length
          if (this.totalItem > totalListItem) {
            this.currentPage += 1
            this.getListPermissionSelect()
          }
        }
      }
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-permission-select', { select: item, permission: this.permissionData })
      this.$emit('update:shall-show-permission-select-modal', false)
      this.busy = false
    },
    checkSelect(item) {
      if (parseInt(item.id, 10) === parseInt(this.permissionData.id, 10)) {
        return false
      }
      return true
    },
    onResetSearch() {
      this.q = ''
      if (this.$refs.refSearch !== undefined) {
        this.$refs.refSearch.focus()
      }
    },
  },
  setup() {
    if (!store.hasModule('permission-store')) store.registerModule('permission-store', storePermission)
    onUnmounted(() => {
      if (store.hasModule('permission-store')) store.unregisterModule('permission-store')
    })

    return {}
  },
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 50vh;
}
</style>
