<template>
  <b-card>
    <b-row>
      <!-- PERMISSION TABLE -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">{{ $t('Permission') }}</span>
            </b-card-title>
          </b-card-header>
          <b-table
            striped
            responsive
            class="mb-0"
            :items="permissionData.permissions"
            :fields="permissionsTableColumns"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template #thead-top="data">
              <b-tr>
                <b-th>
                  <div class="d-flex">
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="selectPermission"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        size="16"
                      />
                      {{ `${$t('Select')} ${$t('Permission')}` }}
                    </b-button>
                    <span class="form-info-box ml-1">{{ permissionSelectData.name }}</span>
                  </div>
                </b-th>
                <b-th>
                  <b-form-checkbox
                    v-model="readAll"
                    switch
                  />
                </b-th>
                <b-th>
                  <b-form-checkbox
                    v-model="writeAll"
                    switch
                  />
                </b-th>
                <b-th>
                  <b-form-checkbox
                    v-model="createAll"
                    switch
                  />
                </b-th>
                <b-th>
                  <b-form-checkbox
                    v-model="deleteAll"
                    switch
                  />
                </b-th>
              </b-tr>
            </template>
            <template #cell(module)="data">
              <div class="d-flex">
                <b-form-checkbox
                  switch
                  @change="onModuleAll($event, data.item)"
                />
                {{ $t(data.value) }}
              </div>
            </template>

            <template #cell(read)="data">
              <b-form-checkbox
                v-model="data.item.read"
                switch
              />
            </template>

            <template #cell(write)="data">
              <b-form-checkbox
                v-model="data.item.write"
                switch
              />
            </template>

            <template #cell(create)="data">
              <b-form-checkbox
                v-model="data.item.create"
                switch
              />
            </template>

            <template #cell(delete)="data">
              <b-form-checkbox
                v-model="data.item.delete"
                switch
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <!-- Permission Select -->
    <permission-select
      v-model="showPermissionSelectModal"
      :title="`${$t('Select')} ${$t('Permission')}`"
      :permission-data="permissionSelectData"
      @update-permission-select="updatePermissionSelect"
      @discard-permission-select="discardPermissionSelect"
    />
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BFormCheckbox,
  BTr,
  BTh,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
} from '@vue/composition-api'
import { required } from '@validations'
import { t } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'
import PermissionSelect from '@/views/center/permission/PermissionSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTr,
    BTh,
    BButton,
    PermissionSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    permissionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      imageFile: null,
      required,
      readAll: false,
      writeAll: false,
      createAll: false,
      deleteAll: false,
      showPermissionSelectModal: false,
      permissionSelectData: {},
    }
  },
  watch: {
    readAll(value) {
      this.permissionData.permissions.forEach(item => {
        item.read = value
      })
    },
    writeAll(value) {
      this.permissionData.permissions.forEach(item => {
        item.write = value
      })
    },
    createAll(value) {
      this.permissionData.permissions.forEach(item => {
        item.create = value
      })
    },
    deleteAll(value) {
      this.permissionData.permissions.forEach(item => {
        item.delete = value
      })
    },
  },
  methods: {
    onModuleAll(event, item) {
      if (item.read !== undefined) {
        item.read = event
      }
      if (item.write !== undefined) {
        item.write = event
      }
      if (item.create !== undefined) {
        item.create = event
      }
      if (item.delete !== undefined) {
        item.delete = event
      }
    },
    selectPermission() {
      if (this.permissionData.permission_id === '') {
        this.permissionSelectData = {
          id: '',
          name: '',
        }
      } else {
        this.permissionSelectData = {
          id: '',
          name: '',
        }
        this.permissionSelectData.id = this.permissionData.permission_id
        this.permissionSelectData.name = this.permissionData.permission_name
      }
      this.showPermissionSelectModal = true
    },
    updatePermissionSelect(data) {
      this.$nextTick(() => {
        this.permissionData.permission_id = data.select.id
        this.permissionData.permission_name = data.select.name

        this.permissionSelectData.id = this.permissionData.permission_id
        this.permissionSelectData.name = this.permissionData.permission_name

        const permissionModule = data.select.permission_module
        permissionModule.forEach(item => {
          const search = this.permissionData.permissions.find(e => e.module === item.module)
          if (item.read !== undefined) {
            search.read = item.read
          }
          if (item.write !== undefined) {
            search.write = item.write
          }
          if (item.create !== undefined) {
            search.create = item.create
          }
          if (item.delete !== undefined) {
            search.delete = item.delete
          }
        })
      })
    },
    discardPermissionSelect() {
    },
  },
  setup() {
    const permissionsTableColumns = [
      { key: 'module', label: t('module') },
      { key: 'read', label: t('read') },
      { key: 'write', label: t('write') },
      { key: 'create', label: t('create') },
      { key: 'delete', label: t('delete') },
    ]

    return {
      permissionsTableColumns,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
