<template>
  <div>
    <b-row>
      <!-- email -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-input
          v-model="generalData.yru_id"
          style="display: none;"
        />
        <b-form-group
          :label="$t('Email')"
          label-for="account-email"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-input-group
              class="m-0"
            >
              <b-input-group-prepend
                is-text
                style="cursor: pointer;"
                @click.prevent="selectYruUser"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="12"
                />
              </b-input-group-prepend>
              <b-form-input
                id="account-email"
                ref="account_email"
                v-model="generalData.email"
                :placeholder="$t('Email')"
                :state="getValidationState(validationContext)"
              />
            </b-input-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>

      <!-- username -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Username')"
          label-for="account-username"
        >

          <validation-provider
            #default="validationContext"
            :name="$t('Username')"
            rules="required"
          >
            <b-input-group
              class="m-0"
            >
              <b-input-group-prepend
                is-text
                style="cursor: pointer;"
                @click.prevent="selectYruUser"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="12"
                />
              </b-input-group-prepend>
              <b-form-input
                id="account-username"
                v-model="generalData.username"
                :placeholder="$t('Username')"
                :state="getValidationState(validationContext)"
              />
            </b-input-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- name -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Full Name')"
          label-for="account-name"
        >
          <b-form-input
            v-model="generalData.name"
            name="name"
            :placeholder="$t('Name')"
          />
        </b-form-group>
      </b-col>

      <!-- company -->
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group
          :label="$t('Company')"
          label-for="account-company"
        >
          <b-form-input
            v-model="generalData.company"
            name="company"
            :placeholder="$t('Company')"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- role -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('User Role')"
          label-for="user-role"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('User Role')"
            rules="required"
          >
            <v-select
              v-model="generalData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- status -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('Status')"
          label-for="user-status"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Status')"
            rules="required"
          >
            <v-select
              v-model="generalData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
              :state="getValidationState(validationContext)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- pricing-plan -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('Pricing Plan')"
          label-for="user-pricing-plan"
        >
          <v-select
            v-model="generalData.pricing_plan_code"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="generalData.pricing_plan_options"
            :reduce="val => val.value"
            :clearable="true"
            input-id="user-pricing-plan"
          />
        </b-form-group>
      </b-col>

      <!-- is_verified -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('Email Verify')"
          label-for="user-verified"
        >
          <b-form-checkbox
            id="user-verified"
            v-model="generalData.is_verified"
            name="check-button"
            switch
            inline
          >
            <span>{{ (generalData.is_verified === true)? $t('Is Verified') : $t('Not Verify') }}</span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

    </b-row>

    <!-- YruUser Select -->
    <yru-user-select
      v-model="showYruUserSelectModal"
      :title="`${$t('Select')} ${$t('FormYruUser.YruUser')}`"
      :yru-user-data="yruUserData"
      @update-yru-user-select="updateYruUserSelect"
      @discard-yru-user-select="discardYruUserSelect"
    />
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { t } from '@/@core/libs/i18n/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { $themeConfig } from '@themeConfig'
import YruUserSelect from '@/views/center/yru-user/YruUserSelect.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    YruUserSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
      showYruUserSelectModal: false,
      yruUserData: {},
    }
  },
  mounted() {
    this.$refs.account_email.focus()
  },
  methods: {
    updateYruUserSelect(data) {
      this.$nextTick(() => {
        this.generalData.yru_id = data.select.id
        this.generalData.username = data.select.username
        this.generalData.name = data.select.name
        this.generalData.email = data.select.email
        this.generalData.role = data.select.type
        this.yruUserData.id = this.generalData.yru_id
        this.yruUserData.username = this.generalData.username
        this.yruUserData.name = this.generalData.name
        this.yruUserData.email = this.generalData.email
        this.yruUserData.type = this.generalData.role

        // this.$refs.refForm.focus()
      })
    },
    discardYruUserSelect() {
    },
    selectYruUser() {
      if (this.generalData.yru_id === '') {
        this.yruUserData = {
          id: 0,
          username: '',
          name: '',
          email: '',
          type: '',
        }
      } else {
        this.yruUserData = {
          id: 0,
          username: '',
          name: '',
          email: '',
          type: '',
        }
        this.yruUserData.id = this.generalData.yru_id
        this.yruUserData.username = this.generalData.username
        this.yruUserData.name = this.generalData.name
        this.yruUserData.email = this.generalData.email
        this.yruUserData.type = this.generalData.role
      }
      this.showYruUserSelectModal = true
    },
  },
  setup(props) {
    let roleOptions = [
      { label: t('Admin'), value: 'admin' },
      { label: t('Staff'), value: 'staff' },
      { label: t('Member'), value: 'member' },
      { label: t('Student'), value: 'student' },
      { label: t('Approver'), value: 'approver' },
    ]
    if (props.generalData.role_options !== undefined) {
      roleOptions = props.generalData.role_options
    }

    let statusOptions = [
      { label: t('Pending'), value: 'pending' },
      { label: t('Active'), value: 'active' },
      { label: t('Inactive'), value: 'inactive' },
    ]
    if (props.generalData.status_options !== undefined) {
      statusOptions = props.generalData.status_options
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      roleOptions,
      statusOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
