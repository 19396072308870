<template>
  <b-card>

    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center mb-2">
          <feather-icon
            icon="LinkIcon"
            size="18"
          />
          <h4 class="mb-0 ml-75">
            {{ $t('Social Links') }}
          </h4>
        </div>
      </b-col>

      <b-col
        v-for="socialField in socialInputs"
        :key="socialField.dataField"
        cols="12"
        md="6"
        lg="6"
      >
        <b-form-group
          :label="socialField.label"
          :label-for="socialField.dataField"
        >

          <validation-provider
            #default="validationContext"
            :name="socialField.dataField"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  size="16"
                  :icon="socialField.icon"
                />
              </b-input-group-prepend>
              <b-form-input
                :id="socialField.dataField"
                v-model="socialData.socialLinks[socialField.dataField]"
                type="url"
              />
            </b-input-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol, BCard,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const socialInputs = [
      {
        icon: 'TwitterIcon',
        dataField: 'twitter',
        label: 'Twitter',
      },
      {
        icon: 'FacebookIcon',
        dataField: 'facebook',
        label: 'Facebook',
      },
      {
        icon: 'InstagramIcon',
        dataField: 'instagram',
        label: 'Instagram',
      },
      {
        icon: 'GithubIcon',
        dataField: 'line',
        label: 'Line',
      },
    ]

    return {
      socialInputs,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
