<template>
  <b-card>
    <b-row>
      <!-- role -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          :label="$t('User Role')"
          label-for="user-role"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('User Role')"
            rules="required"
          >
            <v-select
              v-model="bookingData.role_user"
              :options="bookingData.role_options"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role-user"
              :state="getValidationState(validationContext)"
              :get-option-label="option => $t(option.label)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
} from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    vSelect,

    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem(`${$themeConfig.app.commonSession}-user-data`)),
      required,
    }
  },
  mounted() {
  },
  methods: {
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
