import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultPermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/get_default_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/get_list_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/get_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/add_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/edit_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePermission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/delete_permission', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPermissionOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/permission_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListPermissionSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/center/get_list_permission_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
